




























































import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { organisationModule } from "@/store/modules/OrganisationModule";
import { Profile } from "@/models/Profile";
import { OrganisationDetails } from "@/models/OrganisationDetails";

@Component
export default class Organisations extends Vue {
  private get organisation(): OrganisationDetails {
    return organisationModule.organisation;
  }

  private async aggregate() {
    await adminService.aggregate(this.organisation.id);
  }

  private async analyze() {
    await adminService.analyze(this.organisation.id);
  }
}
